import { showNotification } from "../util/notification";
import i18n from "../i18n";
import history from "./history";
import { updateLogInBlocState } from "../rxjs/loginBloc";
import { url } from "../services/apiConfig";
import { EMPTY, STATUS_CODES } from "../constants";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fetchWrapper(...args: any[]): Promise<Response> {
  const { changePassword, login, maintenance } = url;
  const { pathname } = history.location;
  const currentPath = pathname && (pathname !== login && pathname !== changePassword) ? pathname : EMPTY;
  const { FORBIDDEN, NOT_AUTHENTICATED, SERVICE_UNAVAILABLE } = STATUS_CODES;

  const [func, ...argsArray] = args;

  const handleResponse = (res: Response): Response => {
    switch (res.status) {
      case NOT_AUTHENTICATED:
        /* update session status to rxjs store (session out - true) */
        updateLogInBlocState({ sessionOut: true, serverError: false, currentPath });
        history.push(login);
        break;

      case FORBIDDEN:
        showNotification("error", i18n.t("common.insufficientPriviliges"));
        break;

      case SERVICE_UNAVAILABLE:
        history.push(maintenance);
        break;

      default:
        break;
    }
    return res;
  };

  return (argsArray.length ? func(...argsArray) : func()).then(handleResponse);
}
