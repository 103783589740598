import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import fetch from "isomorphic-fetch";

import { url } from "../../services/apiConfig";
import { AccountContext } from "../../App";
import { fetchForAccount } from "../../services/AppDefinitionService";

import Log from "../../core/Log";
import { fetchWrapper } from "../../core/fetchWrapper";
import { updateLogInBlocState } from "../../rxjs/loginBloc";
import { STATUS_CODES } from "../../constants";
import { showNotification } from "../../util/notification";
import i18n from "../../i18n";
import Spinner from "../common/Spinner";

const { SERVER_ERROR, SUCCESS, TEMPORARY_REDIRECT, FORBIDDEN, SERVICE_UNAVAILABLE } = STATUS_CODES;

class AuthenticatedContainer extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      authenticateResponse: 0,
    };
    this.accountContext = AccountContext;
    this.updateAuthentication = this.updateAuthentication.bind(this);
    this.getAccount = this.getAccount.bind(this);
  }

  componentDidMount() {
    this.checkAuthorizationStatus();
  }

  authStatus = () => {
    return fetch(url.authenticate, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  getAccount() {
    // the user is authenticated, we can get the account
    fetchWrapper(fetchForAccount)
      .then(
        res => {
          this.setState({ authenticateResponse: res.status });
          /* update rxjs store values */
          updateLogInBlocState({ isAccount: true });
          if (res.status === 200) {
            return res.json();
          }
          if (this.state.authenticateResponse === 401) {
            this.updateAuthentication({ type: "failed" });
            return { status: 401 };
          }
          this.updateAuthentication({ type: "error", errorMessage: "Unknown error" });
          Log.error("Server error", res.status);
          return { status: res.status };
        },
        error => {
          this.updateAuthentication("error", 'Something went wrong with "authenticate"');
          Log.error("error 1", error);
        }
      )
      .then(
        result => {
          if (result && result.id) {
            if (result.privileges && result.privileges.includes("access-task")) {
              this.updateAuthentication({ type: "success", account: result });
            } else {
              // show `insufficientPriviliges` error if access-task role is missing on login...
              showNotification("error", i18n.t("common.insufficientPriviliges"));
              this.updateAuthentication({ type: "error" });
              Log.error("Server error", FORBIDDEN);
              return { status: FORBIDDEN };
            }
          }
        },
        error => {
          Log.error(error);
        }
      );
  }

  checkAuthorizationStatus() {
    fetchWrapper(this.authStatus)
      .then(res => {
        const { status } = res;
        this.setState({ authenticateResponse: status });
        status === SUCCESS ? this.getAccount() : this.goToLogin(status);
      })
      .catch(error => {
        Log.error(error);
        this.goToLogin(SERVER_ERROR);
      });
  }

  updateAuthentication(action) {
    switch (action.type) {
      case "success":
        this.props.updateAcccount(action.account);
        break;
      case "failed":
        this.props.updateAcccount(undefined);
        this.goToLogin(SERVER_ERROR);
        break;
      case "error":
        this.props.updateAcccount(undefined);
        this.goToLogin(SERVER_ERROR);
        break;
      default:
        break;
    }
  }

  goToLogin(httpStatus) {
    const { history } = this.props;
    if (httpStatus === TEMPORARY_REDIRECT) {
      /* update rxjs store with store values */
      updateLogInBlocState({ isRedirect: true });
      history.push(url.changePassword);
      return;
    }
    if (httpStatus === SERVICE_UNAVAILABLE) {
      history.push(url.maintenance);
      return;
    }
    /* update rxjs store with store values */
    updateLogInBlocState({ sessionOut: false, serverError: true });
    history.push("/login");
  }

  render() {
    return (
      <AccountContext.Consumer>
        {account => {
          const isOAuthCallback = window.location.pathname.includes("/oauth/callback");
          if (account) {
            return <div>{this.props.children}</div>;
          }
          return isOAuthCallback ? <Spinner open /> : <div>Authenticating...</div>;
        }}
      </AccountContext.Consumer>
    );
  }
}

export default withRouter(AuthenticatedContainer);
