import { makeStyles, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { Fragment } from "react";
import { JuakaliUser } from "../../redux/types";
import { EMPTY } from "../../constants";
import { processNameStyle } from "../Dashboard/styles/dashboardStyles";
import { autoCompleteStyle, dateStyles } from "../Dashboard/styles/toolbarStyles";
import i18next from "i18next";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";

export interface SearchableDropdownProps {
  handleOnChange: (event: object, value: any, reason: string) => void;
  options: JuakaliUser[];
  value?: JuakaliUser | undefined | null;
  defaultValue?: JuakaliUser | undefined | null;
  ListboxProps?: object;
  popupIcon?: React.ReactNode;
  assignee?: string;
}

export default function SearchableDropdown({ handleOnChange, ...rest }: SearchableDropdownProps): JSX.Element {
  const classes = dateStyles();
  const customClasses = autoCompleteStyle();
  return (
    <Fragment>
      <Typography data-testid="assigneeDropdownLable" style={processNameStyle.textStyle}>
        {i18next.t("dashboard.assignee")}
      </Typography>
      <Autocomplete
        {...rest}
        className={`selectAssignee ${customClasses.root}`}
        popupIcon={<ArrowDropDownRoundedIcon />}
        data-testid="assigneeautoComplete"
        id="process-name-select"
        noOptionsText={i18next.t("dashboard.noSuchUser")}
        onChange={handleOnChange}
        autoHighlight
        getOptionLabel={option => (option ? option.firstName : EMPTY)}
        renderOption={option => <div className={classes.optionStyle}>{option ? option.firstName : EMPTY}</div>}
        renderInput={params => (
          <TextField
            {...params}
            data-testid="assigneeDropdownPlaceholder"
            placeholder={i18next.t("common.selectAssignee")}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              classes: {
                root: classes.root,
                notchedOutline: classes.notchedOutline,
                focused: classes.focused,
              },
            }}
            inputProps={{
              ...params.inputProps,
              autoComplete: "off",
            }}
          />
        )}
      />
    </Fragment>
  );
}
