import React, { useContext, useEffect, useState } from "react";
import { Router, Route, Switch } from "react-router-dom";
import * as Sentry from "@sentry/react";
import GoogleAnalytics from "./components/GoogleAnalytics";
import Login from "./views/idm/Login";
import AuthenticatedContainer from "./views/idm/AuthenticatedContainer";
import Master from "./views/layout/Master";
import Dashboard from "./views/Dashboard";
import ProcessEngineView from "./views/process/ProcessEngineView";
import Home from "./views/layout/Home";
import TaskEngineView from "./views/tasks/TaskEngineView";
import "./App.scss";
import ChangePassword from "./views/passwordManagement/ChangePassword";
import ResetPassword from "./views/passwordManagement/ResetPassword";
import Report from "./views/reports/Report";
import { Account } from "./redux/types";
import { StylesProvider, createGenerateClassName } from "@material-ui/styles";
import history from "./core/history";
import ObjectDataList from "./views/reports/ObjectDataList";
import { Notifications } from "react-push-notification";
import { OAuthCallBackComponent } from "./views/common/OAuthCallBackComponent";
import Maintenance from "./views/maintenance";
import { url } from "./services/apiConfig";

export const AccountContext = React.createContext<Account | undefined>(undefined);
export const LogoutContext = React.createContext<any>(undefined);

export const accountContext = () => useContext(AccountContext);

function App(): JSX.Element {
  const [account, setAccount] = useState<Account | undefined>(undefined);

  const updateAcccount = (accountObj: Account): void => setAccount(accountObj);

  useEffect(() => {
    const underMaintenance = !!(window as any).REACT_APP_UNDER_MAINTENANCE;
    if (underMaintenance) {
      history.push(url.maintenance);
      return;
    }
    if (location.pathname === url.maintenance) {
      history.push("/");
      return;
    }
  }, []);

  const generateClassName = createGenerateClassName({
    productionPrefix: "juakali",
  });

  return (
    <StylesProvider generateClassName={generateClassName}>
      <Notifications />
      <Router history={history}>
        <GoogleAnalytics>
          <AccountContext.Provider value={account}>
            <Switch>
              {/* <Route exact path='/' component={Dashboard} /> */}
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/accounts/password/reset" component={ResetPassword} />
              <Route exact path="/accounts/password/change" component={ChangePassword} />
              <Route exact path="/maintenance" component={Maintenance} />
              <AuthenticatedContainer updateAcccount={updateAcccount}>
                {account && account.id && (
                  <Master updateAcccount={updateAcccount}>
                    <Home>
                      <Switch>
                        <Route exact path="/" component={Dashboard} />
                        {/* <Route exact path="/userSettings" component={UserSettings} /> */}
                        <Route path="/process/:type/:appDefinitionKey/new" component={ProcessEngineView} />
                        <Route path="/oauth/callback" component={OAuthCallBackComponent} />
                        <Route path="/process/task/:taskId" component={TaskEngineView} />
                        <Route path="/process/instance/:processInstanceId" component={TaskEngineView} />

                        <Route path="/report/:type/:detailId" component={Report} />
                        <Route path="/report/:type" component={Report} />
                        <Route exact path="/objectdata/:key/:version" component={ObjectDataList} />
                        <Route exact path="/objectdata/:key/:version/:recordId" component={ObjectDataList} />
                      </Switch>
                    </Home>
                  </Master>
                )}
              </AuthenticatedContainer>
            </Switch>
          </AccountContext.Provider>
        </GoogleAnalytics>
      </Router>
    </StylesProvider>
  );
}

export default Sentry.withProfiler(App);
