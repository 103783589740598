import { FormHelperText } from "@material-ui/core";
import { FIELD_TYPES } from "../../../../../constants";
import { InputVariantType } from "../../../../../types/commonTypes";
import React, { ChangeEvent } from "react";
import InputMask from "react-input-mask";
import { withTranslation } from "react-i18next";
import { renderNumberTextView, renderMultilineTextView, renderDataAndTextView } from "./inputTypes";
import CustomFieldLabel from "../CustomFieldLabel";

const TextView = (props: any): JSX.Element => {
  let { getActiveStatus, getLabel, getPlaceholder, getRequiredStatus, getValue, getError, inputMask, name, onChange, type, t } = props;

  let label = getLabel(); /* get form field label */
  let value = getValue(); /* get form field value */
  let error = getError(); /* get form field error */
  let isDisabled = getActiveStatus(); /* get active status*/
  let placeholder = getPlaceholder(); /* get form field placeholder */
  let required = getRequiredStatus();
  const { NUMBER, MULTILINE_INPUT } = FIELD_TYPES;
  // const inValidValueError = error && error.type === INVALID_DATA; reverted in 25.02

  const commonFieldProps = {
    autoComplete: "nope",
    error: !!error,
    fullWidth: true,
    InputLabelProps: { shrink: true },
    InputProps: {
      readOnly: isDisabled,
      disabled: isDisabled,
    },
    label: <CustomFieldLabel label={label} fieldId={name} />,
    name,
    placeholder,
    required,
    value,
    variant: InputVariantType.OUTLINED,
  };

  /* handles input value changes */
  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onChange(e.target.value);
  };

  const renderTextField = (mask?: string): JSX.Element => {
    const commonProps = {
      commonFieldProps,
      props,
      handlers: { onChange },
    };

    switch (type) {
      case NUMBER:
        return renderNumberTextView(commonProps);
      case MULTILINE_INPUT:
        return renderMultilineTextView({ ...commonProps, handlers: { handleChange } });
      default:
        return renderDataAndTextView({ ...commonProps, handlers: { handleChange }, properties: { mask } });
    }
  };

  const renderErrorMessage = (): JSX.Element => {
    return (
      error && (
        <FormHelperText key={name} className="errorMessage" data-testid="textError">
          {t(`validation.${error.type}`, error.params)}
        </FormHelperText>
      )
    );
  };

  return (
    <div className="field">
      <div>
        {inputMask ? (
          /* Input mask Field */
          <InputMask required={required} mask={inputMask} value={value} onChange={handleChange}>
            {() => renderTextField(inputMask)}
          </InputMask>
        ) : (
          /* Text, Number, Date types of fields */
          renderTextField()
        )}
      </div>
      {/* show field error if exists */}
      {renderErrorMessage()}
    </div>
  );
};

export default withTranslation()(TextView);
