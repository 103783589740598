import React from "react";
import MaintenanceIcon from "./MaintenanceIcon";
import { AppBar, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { maintenanceStyles } from "./styles";
import { WithTranslation, withTranslation } from "react-i18next";

const Maintenance = ({ t }: WithTranslation): JSX.Element => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { appBar, gridContainer, header, headerText, icon, subHeader, subHeaderText } = maintenanceStyles(isMobile);

  return (
    <Grid alignItems="center" container direction="column" style={gridContainer}>
      <AppBar style={appBar} position="static" color="transparent">
        <Typography variant="h6">
          <img src={`${process.env.PUBLIC_URL}/images/juakali-logo.png`} alt="Juakali" className="logo" />
        </Typography>
      </AppBar>
      <Grid style={icon} item>
        <MaintenanceIcon />
      </Grid>
      <Grid style={header} item>
        <Typography style={headerText}>{t("maintenance.header")}</Typography>
      </Grid>
      <Grid style={subHeader} item>
        <Typography style={subHeaderText}>{t("maintenance.subHeader")}</Typography>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(Maintenance);
