import React, { useState, useContext, useEffect, useCallback, CSSProperties, useRef, Fragment } from "react";
import { withRouter, NavLink } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Drawer, { DrawerAppContent, DrawerContent, DrawerHeader } from "@material/react-drawer";
import Typography from "@material-ui/core/Typography";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import List, { ListItem, ListItemText, ListGroup } from "@material/react-list";
import "../../App.scss";
import { useTranslation } from "react-i18next";
import { AccountContext } from "../../App";
import Header from "./Header";
import { createMuiTheme, Grid, Hidden } from "@material-ui/core";
import ObjectDefinitionNavList from "../reports/ObjectDefinitionsNavList";
import LanguageSelector from "../common/LanguageSelector";
import { masterStyles } from "./styles";
import { connect } from "react-redux";
import { getDrawerStatus } from "../../redux/selectors/drawerSelector";
import { setDrawerStatus } from "../../redux/actions";
import { makeStyles } from "@material-ui/styles";
import { powderBlue } from "../theme";
import CollpaseIcon from "../common/CollpaseIcon";
import { stackIndex } from "../Dashboard/styles/commonStyles";
import JuakaliVectorIcon from "../common/JuakaliVectorIcon";

function Master(props: any) {
  const { t } = useTranslation();
  const accountContext = useContext(AccountContext);
  const theme = createMuiTheme();
  const [menuOpen, setMenuOpen] = useState(true);
  const [menuOpenSm, setMenuOpenSm] = useState(false);
  const [reportListOpen, setreportListOpen] = useState(true);
  const isItMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isItTablet = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = masterStyles();
  var privileges = accountContext && accountContext.privileges ? accountContext.privileges : [];
  const containerRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const [isVisible, setIsVisible] = useState(true);

  const drawerHeaderBoxShadowStyle = !isVisible && "desktopHeader";

  const isDrawerIntersect = (entries: any) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  };

  useEffect(() => {
    const observer = new IntersectionObserver(isDrawerIntersect, options);
    if (containerRef.current) observer.observe(containerRef.current);

    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, [containerRef, options]);
  useEffect(() => {
    props.setDrawerStatus(true);
  }, []);

  const listItemStyles = makeStyles(theme => ({
    listGroup: {
      backgroundColor: powderBlue,
    },
    listItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "12px",
    },
    textContainer: {
      display: "flex",
      flex: 1,
    },
    expandIcon: {
      display: "flex",
      justifyContent: "flex-end",
    },
  }));
  const iconStyle: CSSProperties = { background: "transparent", border: "none", float: "right", padding: 0, cursor: "pointer" };
  const listItemStyle = listItemStyles();
  const activeNavRef = useRef<HTMLAnchorElement | null>(null);
  const handleNavLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, key?: string, version?: number) => {
    event.preventDefault();
    if (activeNavRef.current) {
      activeNavRef.current.classList.remove("active");
    }
    const clickedElement = event.currentTarget;
    clickedElement.classList.add("active");
    if (key && version) {
      activeNavRef.current = clickedElement;
      props.history.push(`/objectdata/${key}/${version}`);
    } else {
      activeNavRef.current = clickedElement;
      props.history.push("/");
    }
  };
  function renderDrawerContent() {
    return (
      <DrawerContent className={classes.drawerPaperBody}>
        <List singleSelection onClick={onDrawerClose}>
          <NavLink exact to={"/"} onClick={e => handleNavLinkClick(e)}>
            <ListItem className="pointer">
              <div className={classes.drawerIconContainer} ref={containerRef}>
                <DashboardIcon className={`${classes.dashboardIconColor} icon`} />
              </div>
              <ListItemText primaryText={t("menu.dashboard")} className={classes.draweListText} />
            </ListItem>
          </NavLink>
        </List>

        <ListGroup className={listItemStyle.listGroup}>
          <ListItem className={`${listItemStyle.listItem} pointer`} onClick={() => setreportListOpen(prev => !prev)}>
            <div className={classes.listItemWrapper}>
              <AssignmentIcon className={classes.dashboardIconColor} />
            </div>
            <div className={listItemStyle.textContainer}>
              <ListItemText primaryText={t("menu.reports")} className={classes.draweListText} />
            </div>
            <div className={listItemStyle.expandIcon}>
              {reportListOpen ? (
                <ExpandLessRoundedIcon className={classes.dashboardIconColor} />
              ) : (
                <ExpandMoreRoundedIcon className={classes.dashboardIconColor} />
              )}
            </div>
          </ListItem>
        </ListGroup>

        <div className={classes.drawerContent}>
          {reportListOpen && privileges && privileges.indexOf("access-bo") !== -1 && (
            <ObjectDefinitionNavList onDrawerClose={onDrawerClose} handleNavLinkClick={handleNavLinkClick}></ObjectDefinitionNavList>
          )}
        </div>
      </DrawerContent>
    );
  }

  function toggleDrawer() {
    if (isItTablet) {
      setMenuOpenSm(!menuOpenSm);
      props.setDrawerStatus(!menuOpenSm);
    } else {
      setMenuOpen(!menuOpen);
      props.setDrawerStatus(!menuOpen);
    }
  }

  const onDrawerClose = useCallback(() => {
    setMenuOpenSm(!setMenuOpenSm);
    menuOpenSm && props.setDrawerStatus(true);
  }, [setMenuOpenSm, props.setDrawerStatus]);

  const LanguageComponent = (): JSX.Element => {
    return (
      <div className={classes.drawerLanguageBar}>
        <div className={classes.languageBar}>
          <LanguageSelector />
        </div>
      </div>
    );
  };
  const onDrawerCloseSmDown = useCallback(() => {
    setMenuOpen(false);
    props.setDrawerStatus(false);
  }, [setMenuOpen, props.setDrawerStatus]);
  return (
    <div className="drawer-container">
      <Hidden smDown>
        <Drawer dismissible open={menuOpen} onClose={onDrawerCloseSmDown}>
          <DrawerHeader className={`${drawerHeaderBoxShadowStyle} ${classes.drawerHeader}`}>
            <JuakaliVectorIcon />
            <button onClick={toggleDrawer} style={iconStyle}>
              <CollpaseIcon />
            </button>
          </DrawerHeader>
          {renderDrawerContent()}
          <LanguageComponent />
        </Drawer>
      </Hidden>
      <Hidden mdUp>
        <Drawer
          style={stackIndex}
          open={menuOpenSm}
          modal
          onClose={() => {
            setMenuOpenSm(false);
            props.setDrawerStatus(true);
          }}
        >
          <DrawerHeader className={`${drawerHeaderBoxShadowStyle} ${classes.drawerHeader}`}>
            <JuakaliVectorIcon />
          </DrawerHeader>
          {renderDrawerContent()}
          <LanguageComponent />
        </Drawer>
      </Hidden>
      <DrawerAppContent className="drawer-app-content">
        <Header
          menuOpen={menuOpen}
          menuOpenSm={menuOpenSm}
          authInfo={props.authInfo}
          updateAcccount={props.updateAcccount}
          toggleDrawer={toggleDrawer}
          mobileView={isItMobile}
          tabletView={isItTablet}
          drawerStatus={props.drawerStatus}
        />
        <Grid className="content-container">{props.children}</Grid>
      </DrawerAppContent>
    </div>
  );
}

function mapDispatchToProps(dispatch: any) {
  const actions = {
    setDrawerStatus: (status: any) => dispatch(setDrawerStatus(status)),
  };
  return actions;
}

function mapStateToProps(state: any) {
  const drawerStatus = getDrawerStatus(state);
  return {
    drawerStatus,
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Master) as any) as any;
