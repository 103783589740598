import React, { Fragment, useState, useRef, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  Tooltip,
  DialogContent,
  DialogActions,
  Button,
  FormLabel,
  MuiThemeProvider,
  IconButton,
  CircularProgress,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Webcam from "react-webcam";
import { url } from "../../../../../services/apiConfig";
import { fetchWrapper } from "../../../../../core/fetchWrapper";
import { pictureFieldStyles } from "../../styles";
import { ButtonTheme, EditButtontheme, zoomButtonTheme } from "../../../../theme";
import PictureEditor from "./ImageEditor";
import { ZoomIn, ZoomOut } from "@material-ui/icons";
import { addSentryLogs, handleImageDownload, handleImageUpload } from "./utils";
import DownloadIcon from "../../../../common/DownloadIcon";
import * as Sentry from "@sentry/react";
import { MAX_ZOOM_LEVEL, MIN_ZOOM_LEVEL } from "../../../constants";
import { updateImageEditorBlocState } from "../../../../../rxjs/ImageEditorBloc";

const useStyle = makeStyles((theme: any) => ({
  modalHeight: {
    [theme.breakpoints.up("md")]: {
      height: "590px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "590px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "590px",
    },
  },
}));

export default function WebcamDialog(props: any) {
  const {
    getDialogStatus,
    getCurrentShot,
    isShowCamera,
    getValue,
    handleRetake,
    handleClose,
    handleCapture,
    handleSave,
    isViewScreen,
    handleCloseViewScreen,
    handlePictureEditor,
    handlePictureSelector,
    handleDismiss,
    getLabel,
  } = props;
  const label = getLabel();
  let value = getValue();
  let showCamera = isShowCamera();
  const currentShot = getCurrentShot();
  let viewScreen = isViewScreen();
  /* Stylesheet for picture Field */
  let { classes, t, name } = props.props;
  const webcamRef = React.useRef(null);
  const [disableDownload, setDisableDownload] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const { pictureDialogActions, dialogActionButtons, downloadButtonStyle, downloadIcon } = pictureFieldStyles(disableDownload);

  const exitIcon = (): JSX.Element => {
    return (
      <div className={classes.closeIcon}>
        <MuiThemeProvider theme={ButtonTheme}>
          <Tooltip placement="bottom" title={t("common.close") as string}>
            <IconButton color="inherit">
              <CloseIcon
                id="exit"
                onClick={() => {
                  viewScreen || !currentShot ? handleCloseViewScreen() : handleClose();
                  setZoomLevel(1);
                }}
              />
            </IconButton>
          </Tooltip>
        </MuiThemeProvider>
      </div>
    );
  };

  const zoomInIcon = (): JSX.Element => {
    return (
      <div className={classes.closeIcon}>
        <MuiThemeProvider theme={zoomButtonTheme}>
          <Tooltip placement="bottom" title={t("common.zoomin") as string}>
            <IconButton color="inherit">
              <ZoomIn id="exit" onClick={increaseZoom} className={classes.zoomButton} />
            </IconButton>
          </Tooltip>
        </MuiThemeProvider>
      </div>
    );
  };
  const zoomOutIcon = (): JSX.Element => {
    return (
      <div className={classes.closeIcon}>
        <MuiThemeProvider theme={zoomButtonTheme}>
          <Tooltip placement="bottom" title={t("common.zoomout") as string}>
            <IconButton color="inherit">
              <ZoomOut id="exit" onClick={decreaseZoom} className={classes.zoomButton} />
            </IconButton>
          </Tooltip>
        </MuiThemeProvider>
      </div>
    );
  };

  const downloadButton = (): JSX.Element => (
    <>
      <Button
        id="custom"
        style={downloadButtonStyle}
        data-testid="confirmationModal"
        onClick={() => {
          handleImageDownload(`${url.file}/${value}`, label, setDisableDownload, value);
        }}
      >
        <DownloadIcon style={downloadIcon} />
        {t("common.download")}
      </Button>
    </>
  );
  const usePhotoButton = (): JSX.Element => {
    return (
      <>
        <Button
          onClick={() => {
            handleSave(url, fetchWrapper, addSentryLogs, handleImageUpload);
          }}
          className={dialogActionButtons}
          data-testid="usePhoto"
        >
          <FormLabel component="article" className={classes.dialogButtonText}>
            {t("picture.usePhoto")}
          </FormLabel>
        </Button>
      </>
    );
  };
  const retakeButton = (): JSX.Element => {
    return (
      <Button
        onClick={() => {
          handleRetake();
        }}
        className={dialogActionButtons}
        data-testid="retake"
      >
        <FormLabel component="article" className={classes.dialogButtonText}>
          {t("common.retake")}
        </FormLabel>
      </Button>
    );
  };
  const takePictureButton = (): JSX.Element => {
    return (
      <Button
        onClick={() => {
          handleCapture(webcamRef.current);
        }}
        className={dialogActionButtons}
        data-testid="takePicture"
      >
        <FormLabel component="article" className={classes.dialogButtonText}>
          {t("picture.takePicture")}
        </FormLabel>
      </Button>
    );
  };

  const editPhotoButton = (): JSX.Element => {
    return (
      <>
        <Button
          onClick={() => {
            handlePictureEditor(true);
            handlePictureSelector(false);
            handleDismiss();
            updateImageEditorBlocState({
              isZoomVisible: false,
              isRotateVisible: false,
              isCroppingEnabled: true,
              isDisable: true,
            });
          }}
          className={classes.dialogButtonText}
          data-testid="usePhoto"
        >
          <FormLabel component="article" className={classes.dialogButtonText}>
            {t("common.editPhoto")}
          </FormLabel>
        </Button>
      </>
    );
  };

  const increaseZoom = () => {
    if (zoomLevel < MAX_ZOOM_LEVEL) {
      setZoomLevel(Math.min(zoomLevel + 0.1, MAX_ZOOM_LEVEL));
    }
  };

  const decreaseZoom = () => {
    if (zoomLevel > MIN_ZOOM_LEVEL) {
      setZoomLevel(Math.max(zoomLevel - 0.1, MIN_ZOOM_LEVEL));
    }
  };
  return (
    <Dialog fullWidth={true} id={"pictureDialog"} open={getDialogStatus()} classes={{ paper: `${classes.paper}` }}>
      {/* Dialog title */}
      <DialogTitle classes={{ root: `${classes.dialogTitle}` }}>
        <div className={classes.dialogTitleContainer}>
          <span className={classes.dialogTitleText}>
            {showCamera && !currentShot ? t("common.camera") : viewScreen ? name : t("common.captureImage")}
          </span>

          <div className={classes.zoomContainer}>
            {viewScreen && zoomInIcon()}
            {viewScreen && zoomOutIcon()}
            {/* Exit Icon */}
            {exitIcon()}
          </div>
        </div>
      </DialogTitle>
      {/* Dialog Content */}
      {showCamera && !currentShot && !(value && value.trim()) ? (
        <DialogContent>
          <Webcam
            screenshotFormat="image/jpeg"
            ref={webcamRef}
            forceScreenshotSourceSize
            audio={false}
            videoConstraints={{ facingMode: { ideal: "environment" } }}
            className={classes.webcam}
            screenshotQuality={0.4}
          />
        </DialogContent>
      ) : (
        <DialogContent classes={{ root: `${classes.thumbnailDialogContent}` }}>
          {currentShot ? (
            // Temporarily captured image content
            <div className={classes.webCamImageContainer}>
              <img className={classes.webCamImageFit} src={currentShot} draggable={false} />
            </div>
          ) : (
            value &&
            value.trim() &&
            !showCamera && (
              // Showing already stored image content
              <div className={classes.webCamImageContainer}>
                <img
                  className={classes.webCamImageFit}
                  src={`${url.file}/${value}`}
                  draggable={false}
                  style={{
                    transform: `scale(${zoomLevel})`,
                    objectFit: "contain",
                    transformOrigin: zoomLevel <= 1 ? "center" : "left top",
                  }}
                  alt="Stored"
                />
              </div>
            )
          )}
        </DialogContent>
      )}
      {/* Dialog Icon Buttons */}
      <DialogActions
        classes={{ root: `${classes.customDialogActions}`, action: `${classes.customDialogAction}` } as any}
        style={viewScreen ? null : pictureDialogActions}
      >
        {viewScreen ? (
          disableDownload ? (
            <CircularProgress size="2rem" />
          ) : (
            <Fragment>
              <ThemeProvider theme={EditButtontheme}>{downloadButton()}</ThemeProvider>
            </Fragment>
          )
        ) : (value && value.trim() && !showCamera) || currentShot ? (
          <Fragment>
            <ThemeProvider theme={EditButtontheme}>
              {usePhotoButton()}
              {retakeButton()}
              {editPhotoButton()}
            </ThemeProvider>
          </Fragment>
        ) : (
          <ThemeProvider theme={EditButtontheme}>{takePictureButton()}</ThemeProvider>
        )}
      </DialogActions>
    </Dialog>
  );
}
