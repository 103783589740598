import { AnyAction, createReducer } from "redux-starter-kit";
import { LoanReportDetailData, TypedAction } from "../../types";
import { setLoanReportDetailData } from "../../actions";
import { fetchForCompletedProcessTasks } from "../../../services/TaskService";
import Log from "../../../core/Log";
import { fetchWrapper } from "../../../core/fetchWrapper";

export interface LoanReportDetailDataAction extends AnyAction {
  payload?: LoanReportDetailData[];
}

const loanReportTasksReducer = createReducer<LoanReportDetailData[], AnyAction>([], {
  [setLoanReportDetailData.type]: (state: LoanReportDetailData[], action: TypedAction<LoanReportDetailData>): LoanReportDetailData[] => {
    if (!(action && action.payload)) {
      return state;
    }

    const payload: LoanReportDetailData = action.payload;

    let foundId = false;
    state.forEach((data): void => {
      if (data.id === payload.id) {
        data.tasks = payload.tasks;
        foundId = true;
      }
    });
    if (!foundId) {
      state.push({ id: payload.id, tasks: action.payload.tasks });
    }

    return state;
  },
});

export default loanReportTasksReducer;

export function fetchLoanReportDetailData(processInstanceId: string): (dispatch: any) => void {
  return (dispatch: any) => {
    var completedProcessTasksPromise = fetchWrapper(fetchForCompletedProcessTasks, processInstanceId);
    return completedProcessTasksPromise
      .then(
        (response: Response): any => { if (response.status === 200) return response.json() },
        (error: string): any => {
          Log.error(error);
        }
      )
      .then((result: any) => {
        dispatch(setLoanReportDetailData({ id: processInstanceId, tasks: result.data }));
      });
  };
}
