// @flow
/* eslint-disable no-param-reassign */
import { AnyAction, createReducer } from "redux-starter-kit";
import { LoanReport, defaultLoanReport } from "../../types";
import { fetchForProcessInstances } from "../../../services/ProcessInstanceService";
import Log from "../../../core/Log";
import { setLoanReport, setLoanReportLoading } from "../../actions";
import { fetchWrapper } from "../../../core/fetchWrapper";

const loanReportReducer = createReducer<LoanReport, AnyAction>(defaultLoanReport, {
  [setLoanReportLoading.type]: (state: LoanReport, action: AnyAction) => {
    state.isLoading = action.payload;
  },
  [setLoanReport.type]: (state: LoanReport, action: AnyAction) => {
    state.loans = action.payload;
  },
});

export default loanReportReducer;

export function fetchLoanApplications(): (dispatch: any) => void {
  return (dispatch: any) => {
    dispatch(setLoanReportLoading(true));
    var fetchProcessInstancesPromise = fetchWrapper(fetchForProcessInstances, "LOAN");
    return fetchProcessInstancesPromise
      .then(
        (response: any) => response && response.status === 200 && response.json(),
        (error: string) => {
          dispatch(setLoanReportLoading(false));
          Log.error(error);
        }
      )
      .then((result: any) => {
        dispatch(setLoanReport(result.data));
        dispatch(setLoanReportLoading(false));
      });
  };
}
