import { FormHelperText, MuiThemeProvider, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { dropdownOptionStyle, searchableDropdownStyle } from "../../styles";
import { getCurrentDropdownOption } from "../../../../../util/helper";
import { DropdownOption } from "../../../../../types/formtypes";
import { EMPTY } from "../../../../../constants";
import CustomFieldLabel from "../CustomFieldLabel";
import { checkForAccessTestRole } from "../helper";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";

const DropdownFieldView = (props: any) => {
  let { getActiveStatus, getError, getLabel, getRequiredStatus, getValue, name, onChange, t, getDropdownOptionList } = props;
  // const [openDropDown, setOpenDropDown] = useState(false);
  let label = getLabel();
  let value = getValue();
  let error = getError();
  let isDisabled = getActiveStatus();
  let required = getRequiredStatus();
  const { optionBlock, optionValue, popupIcon } = dropdownOptionStyle();
  const renderKeyValue = checkForAccessTestRole();
  // const inValidValueError = error && error.type === INVALID_DATA; reverted in 25.02

  let DropdownOptionList = getDropdownOptionList();
  let currentValue = getCurrentDropdownOption(DropdownOptionList, value) as DropdownOption | undefined;
  // const isInvalidValue = value && value.toString();
  // if (!currentValue && value) {
  //   currentValue = { name: isInvalidValue, value: isInvalidValue };
  // }
  // const handleDropdownOpenState = (state: boolean) => {
  //   if (!isDisabled) setOpenDropDown(state);
  // };
  const handleChange = (e: any, selectedOption: DropdownOption | null): void => {
    onChange((selectedOption && selectedOption.value) || EMPTY);
    // handleDropdownOpenState(false);
  };

  const renderOption = (option: DropdownOption) =>
    option ? (
      renderKeyValue ? (
        <div style={optionBlock}>
          <span>{option.name}</span>
          <span style={optionValue}>{`(${option.value})`}</span>
        </div>
      ) : (
        option.name
      )
    ) : (
      EMPTY
    );

  // useEffect(() => {
  //   const scrollingContainer = document.querySelector(".content-container");
  //   if (!scrollingContainer || !openDropDown) return;
  //   const handleScroll = () => handleDropdownOpenState(false);
  //   scrollingContainer.addEventListener("scroll", handleScroll);
  //   return () => scrollingContainer.removeEventListener("scroll", handleScroll);
  // }, [openDropDown]);

  return (
    <div className="field">
      <div>
        <MuiThemeProvider theme={searchableDropdownStyle}>
          <Autocomplete
            // open={openDropDown}
            data-testid="dropdownField"
            disabled={isDisabled}
            fullWidth={true}
            clearText={t("common.clear")}
            closeText={t("common.close")}
            popupIcon={<ArrowDropDownRoundedIcon style={popupIcon} />}
            // popupIcon={
            //   <ArrowDropDownRoundedIcon style={popupIcon} onClick={() => handleDropdownOpenState(!openDropDown)} data-testid="dropdownIcon" />
            // }
            openText={t("common.open")}
            noOptionsText={EMPTY}
            id={name}
            value={currentValue || null}
            defaultValue={currentValue || null}
            options={DropdownOptionList}
            autoHighlight
            onChange={handleChange}
            getOptionLabel={option => option.name}
            renderOption={renderOption}
            // onClick={() => handleDropdownOpenState(!openDropDown)}
            // onBlur={() => handleDropdownOpenState(false)}
            renderInput={params => (
              <TextField
                {...params}
                label={<CustomFieldLabel label={label} fieldId={name} />}
                // onClick={() => handleDropdownOpenState(!openDropDown)}
                // onChange={() => handleDropdownOpenState(true)}
                variant="outlined"
                error={!!error}
                required={required}
              />
            )}
          />
        </MuiThemeProvider>
      </div>
      {/* show field error if exists */}
      {error && (
        <FormHelperText key={name} className="errorMessage" data-testid="dropdownError">
          {t(`validation.${error.type}`, error.params)}
        </FormHelperText>
      )}
    </div>
  );
};

export default withTranslation()(DropdownFieldView);
