import React, { Fragment, useEffect } from "react";
import i18n from "../../i18n";
import _ from "lodash";
import { Document, ObjectReportDetailTypes, StateType } from "../../redux/types";
import { SortParams } from "../../types/commonTypes";
import { useStyles } from "./objectDataStyles";
import { FilterParam, ObjectData } from "../../datastore/dataStoreTypes";
import ReadOnlyFormSection from "../TeamTask/ReadOnlyFormSection";
import ObjectDataToolkit from "./ObjectDataToolkit";
import { fetchObjectReportDetailRemote, resetObjectReportDetailState } from "../../redux/actions";
import { connect } from "react-redux";
import getObjectReportDetailData from "../../redux/selectors/objectReportDetailSelectore";
import { SUCCESS } from "../../constants";

interface DispatchProps {
  resetObjectReportDetailState: () => void;
  fetchObjectReportDetail: (id: string, handleBackToList: any) => void;
}
interface StateProps {
  objectReportDetailState: ObjectReportDetailTypes;
}
interface OwnProps {
  content: ObjectData[];
  dictionaries: any;
  documents: Document[];
  filterParams: { filters: any; pageNumber: number; pagingSize: number; sortingOrder: SortParams; filterParams: FilterParam[] | undefined };
  formData: any;
  handleBackToList: () => void;
  history: any;
  id: string;
  t: i18n.TFunction;
  formType?: string;
}

const ObjectDataForm = (props: OwnProps & StateProps & DispatchProps): JSX.Element => {
  const {
    objectReportDetailState,
    fetchObjectReportDetail,
    resetObjectReportDetailState,
    formData,
    dictionaries,
    handleBackToList,
    id,
    formType,
  } = props;
  const { detail, status } = objectReportDetailState;

  const variables = status == SUCCESS && Object.assign({ JUAKALI_PROCESS_INSTANCE_ID: id }, detail.data);

  const { readOnlySection } = useStyles();
  useEffect(() => {
    fetchObjectReportDetail(id, handleBackToList);
    return () => {
      resetObjectReportDetailState();
    };
  }, []);
  return (
    <Fragment>
      <div className={`background-color px-2 ${readOnlySection}`}>
        <ObjectDataToolkit {...props} />
        {formData && variables && (
          <ReadOnlyFormSection
            handleGoBackOnTeamView={handleBackToList}
            formType={formType}
            formData={formData}
            dictionaries={dictionaries}
            variables={{ taskVariables: {}, processVariables: variables }}
            isReport={true}
            processInstance={{}}
          />
        )}
      </div>
    </Fragment>
  );
};

function mapStateToProps(state: StateType): StateProps {
  const objectReportDetailState = getObjectReportDetailData(state);
  return { objectReportDetailState };
}
function mapDispatchToProps(dispatch: any): DispatchProps {
  const actions = {
    fetchObjectReportDetail: (id: string, handleBackToList: any) => dispatch(fetchObjectReportDetailRemote({ id, handleBackToList })),
    resetObjectReportDetailState: () => dispatch(resetObjectReportDetailState()),
  };
  return actions;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ObjectDataForm);
