import { Subject } from "rxjs";
import { fetchWrapper } from "../core/fetchWrapper";
import { ApiResult, PagedApiResult } from "../types/commonTypes";
import { DynamicJson } from "../datastore/dataStoreTypes";

export interface ApiExecutorState<T> {
  status: number | undefined;
  pagedApiResult: PagedApiResult<T> | undefined | DynamicJson | DynamicJson[] | ApiResult<T>;
}

export class ApiExecutor<T extends ApiExecutorState<T>, P> {
  subject: Subject<unknown>;
  state: ApiExecutorState<T>;
  apiFunction: () => Promise<Response> | void;

  constructor(apiFunction: () => Promise<Response> | void) {
    this.subject = new Subject();
    this.state = { status: undefined, pagedApiResult: undefined };
    this.apiFunction = apiFunction;
  }

  subscribe = (setState: any) => this.subject.subscribe(setState);

  unsubscribe = (): void => this.subject.unsubscribe();

  updateApiFunction = (newFunction: () => Promise<Response> | void) => (this.apiFunction = newFunction);

  callApi(apiFunctionArguments: P): void {
    this.state = { status: undefined, pagedApiResult: undefined };
    this.subject.next(this.state);
    var fetchPromise = apiFunctionArguments ? fetchWrapper(this.apiFunction, { ...apiFunctionArguments }) : fetchWrapper(this.apiFunction);
    fetchPromise.then(
      (response: Response) => {
        if (response.status === 200) {
        response.json().then((result: PagedApiResult<T> | undefined | DynamicJson | ApiResult<T>) => {
          this.state = { status: response.status, pagedApiResult: result };
          this.subject.next(this.state);
        });
      } else {
        this.state = { status: 500, pagedApiResult: undefined };
        this.subject.next(this.state);
      }
    },
      (errorResponse: any) => {
        this.state = { status: 500, pagedApiResult: undefined };
        this.subject.next(this.state);
      }
    );
  }
}
