import React, { useState } from "react";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  IconButton,
  MuiThemeProvider,
  Tooltip,
  makeStyles,
  DialogActions,
  Button,
  FormLabel,
  ThemeProvider,
} from "@material-ui/core";
import { ButtonTheme, EditButtontheme } from "../../../../theme";
import CloseIcon from "@material-ui/icons/Close";
import { url } from "../../../../../services/apiConfig";
import { fetchWrapper } from "../../../../../core/fetchWrapper";
import { pictureFieldStyles } from "../../styles";
import RefreshIcon from "@material-ui/icons/Refresh";
import TuneIcon from "@material-ui/icons/Tune";
import CropIcon from "@material-ui/icons/Crop";
import EditorControl from "./EditorControls";
import { addSentryLogs, handleImageUpload } from "./utils";
import Spinner from "../../../../common/Spinner";
import ImageEditorBloc, { ImageEditorState, defaultImageEditorState, updateImageEditorBlocState } from "../../../../../rxjs/ImageEditorBloc";
import { useBloc } from "../../../../../rxjs/blocBuilder";

const useStyle = makeStyles((theme: any) => ({
  modalHeight: {
    [theme.breakpoints.up("md")]: {
      minHeight: "80vh",
      width: "800px",
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "80vh",
      width: "800px",
    },
    [theme.breakpoints.up("xl")]: {
      minHeight: "80vh",
      width: "800px",
    },
  },
}));

export default function PictureEditor(props: any): JSX.Element {
  const {
    handleCloseViewScreen,
    handleClose,
    isViewScreen,
    getCurrentShot,
    getValue,
    getPictureEditorStatus,
    handleSave,
    handleGalleryDialog,
    imageCapture,
    getLoadingStatus,
    handlePictureEditor,
  } = props;
  const value = getValue();
  const imageBloc = ImageEditorBloc.getInstance();
  const effectState: any = useBloc<ImageEditorState>(defaultImageEditorState, imageBloc.getSubject());
  const { isZoomVisible, isRotateVisible, isCroppingEnabled, isDisable } = effectState.data;

  const WebcamDialogStyle = useStyle();
  const viewScreen = isViewScreen();
  const currentShot = getCurrentShot();
  const imgUrl = currentShot ? currentShot : `${url.file}/${value}`;
  const { classes, t } = props.props;
  const [previewCanvasRef, setPreviewCanvasRef] = useState<any>();
  const isLoading = getLoadingStatus();
  const { saveStyle, cancelStyle, rotateButton, zoomButton, cropButton } = pictureFieldStyles(
    isDisable,
    isZoomVisible,
    isRotateVisible,
    isCroppingEnabled
  );

  const exitIcon = (): JSX.Element => {
    return (
      <div className={classes.closeIcon}>
        <MuiThemeProvider theme={ButtonTheme}>
          <Tooltip placement="bottom" title={t("common.close") as string}>
            <IconButton color="inherit">
              <CloseIcon
                data-testid="exitIcon"
                id="exit"
                onClick={() => {
                  viewScreen || !imgUrl ? handleCloseViewScreen() : handleClose();
                }}
              />
            </IconButton>
          </Tooltip>
        </MuiThemeProvider>
      </div>
    );
  };
  function handleCropButtonClick() {
    updateImageEditorBlocState({
      isZoomVisible: false,
      isRotateVisible: false,
      isCroppingEnabled: true,
      isDisable: true,
    });
  }

  function handleZoom() {
    updateImageEditorBlocState({
      isZoomVisible: true,
      isRotateVisible: false,
      isCroppingEnabled: false,
      isDisable: true,
    });
  }
  function handleRotate() {
    updateImageEditorBlocState({
      isZoomVisible: false,
      isRotateVisible: true,
      isCroppingEnabled: false,
      isDisable: true,
    });
  }
  const handleDisable = (value: boolean) => {
    updateImageEditorBlocState({
      isZoomVisible: isZoomVisible,
      isRotateVisible: isRotateVisible,
      isCroppingEnabled: isCroppingEnabled,
      isDisable: value,
    });
  };
  const handleRef = (ref: any) => {
    setPreviewCanvasRef(ref);
  };
  const handleImageCancle = () => {
    viewScreen || currentShot != null ? handleCloseViewScreen() : handleClose();
    handleGalleryDialog(false);
    updateImageEditorBlocState({
      isZoomVisible: false,
      isRotateVisible: false,
      isCroppingEnabled: false,
      isDisable: false,
    });
  };
  const handleImageSave = () => {
    imageCapture(previewCanvasRef.toDataURL());
    handleSave(url, fetchWrapper, addSentryLogs, handleImageUpload);
    handlePictureEditor(false);
    handleGalleryDialog(false);
    updateImageEditorBlocState({
      isZoomVisible: false,
      isRotateVisible: false,
      isCroppingEnabled: false,
      isDisable: false,
    });
  };

  const editControlSetting = (): JSX.Element => {
    return (
      <ThemeProvider theme={EditButtontheme}>
        <Button data-testid="cropButton" onClick={() => handleCropButtonClick()} startIcon={<CropIcon />} style={cropButton}>
          {t("common.crop")}
        </Button>
        <Button data-testid="rotateButton" onClick={() => handleRotate()} startIcon={<RefreshIcon />} style={rotateButton}>
          {t("common.rotate")}
        </Button>
        <Button data-testid="adjustmentButton" onClick={() => handleZoom()} startIcon={<TuneIcon />} style={zoomButton}>
          {t("common.adjustment")}
        </Button>
      </ThemeProvider>
    );
  };
  const ConfirmationActions = (): JSX.Element => {
    return (
      <>
        <Button className="editorSecondaryButton" color="primary" onClick={handleImageCancle} style={cancelStyle} data-testid="CancleButton">
          <FormLabel component="article" className={classes.editorCancleDialogText}>
            {t("common.cancel")}
          </FormLabel>
        </Button>
        <Button
          className="editorPrimaryButton"
          color="primary"
          onClick={handleImageSave}
          style={saveStyle}
          data-testid="SaveButton"
          id="saveButton"
          disabled={!isDisable}
        >
          <FormLabel component="article" className={classes.editorDialogText}>
            {t("common.save")}
          </FormLabel>
        </Button>
      </>
    );
  };

  return (
    <Dialog
      fullWidth={true}
      id={"pictureDialog"}
      open={getPictureEditorStatus()}
      classes={{ paper: `${classes.paper}`, paperScrollPaper: `${WebcamDialogStyle.modalHeight}` }}
      data-testid="editorDialog"
    >
      <div>
        <DialogTitle classes={{ root: `${classes.dialogTitle}` }}>
          <span className={classes.dialogTitleText}> {t("common.editPhoto")}</span>
          {exitIcon()}
        </DialogTitle>
        <DialogContent id="test" classes={{ root: classes.editDialog }}>
          <EditorControl {...props} effect={effectState.data} handleDisable={handleDisable} handleRef={handleRef} />
        </DialogContent>
        <DialogActions classes={{ root: `${classes.EditorDialogActions}`, action: `${classes.EditorDialogActions}` } as any}>
          {editControlSetting()}
        </DialogActions>
        <DialogActions classes={{ root: `${classes.EditorSaveCancelDialog}` }}>{ConfirmationActions()}</DialogActions>
      </div>
      <Spinner open={isLoading} />
    </Dialog>
  );
}
