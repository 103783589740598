import { createMuiTheme } from "@material-ui/core";
import {
  aviaryBlue,
  black,
  cobalt,
  grey,
  primaryColor,
  solitude,
  NONE_MESSAGE_TEXT,
  greyIconBg,
  cancelColor,
  disabledButtonColor,
  NONE_MESSAGE_BACKGROUND,
  gainsBoro,
} from "./../../theme";
import { CSSProperties } from "@material-ui/styles";

export const dropdownTheme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      root: {
        paddingBottom: "1em",
      },
      focused: {
        color: "rgba(0, 0, 0, 0.54) !important",
      },
    },
  },
});

export const radioTheme = createMuiTheme({
  overrides: {
    MuiFormControlLabel: {
      label: {
        fontSize: "unset !important",
        alignSelf: "center !important",
      },
    },
    MuiFormGroup: {
      root: {
        flexDirection: "row",
      },
    },
  },
});

export const checkboxTheme = createMuiTheme({
  overrides: {
    MuiCheckbox: {
      root: {
        "&$checked": {
          color: `${primaryColor[800]} !important`,
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: "unset !important",
        alignSelf: "center !important",
      },
    },
  },
});

export const formulaFieldTheme = createMuiTheme({
  overrides: {
    MuiFormControlLabel: {
      label: {
        fontSize: "unset !important",
        alignSelf: "flex-end !important",
      },
    },
  },
});

export const styles = ({ disabled, error }: any): any => ({
  root: {
    borderRadius: "4px",
    width: "100%",
  },
  error: {
    color: "#a94442",
    backgroundColor: "#f2dede",
    borderColor: "#ebccd1 !important",
  },
  canvasTitleContainer: { display: "flex", justifyContent: "space-between", flexFlow: "wrap" },
  canvasTitleIcons: { display: "flex", marginTop: "10px" },
  canvasUndoIcon: { color: "#3f51b5", marginRight: "10px" },
  canvasPenColor: {
    width: "25px",
    height: "25px",
    float: "right",
    padding: "2px",
  },
  canvasFormLabel: { paddingBottom: "10px" },
  canvasThumbnail: { width: "4em", height: "4em", padding: "5px", border: "thin #3f51b5 solid" },
  gpsThumbnail: { width: "3em", height: "3em", color: disabled ? "gray" : error ? "#f44336" : "#3f51b5" },
  gpsFixed: { color: "green" },
  editIconStyle: {
    color: disabled ? "gray" : error ? "#f44336" : "#3f51b5",
    border: disabled ? "thin grey solid" : error ? "thin #f44336 solid" : "thin #3f51b5 solid",
  },
  locationMargin: {
    marginTop: "24px",
  },
  formulaTextStyle: { textAlign: "right" },
});

const commonButtonStyle = {
  color: cobalt,
  paddingLeft: "8px",
  fontSize: "0.875rem",
  letterSpacing: "0.0200012em",
  lineHeight: "19px",
  fontWeight: 500,
};
const ButtonStyle = {
  color: cobalt,
  paddingLeft: "8px",
  fontSize: "18px",
  fontVariant: "all-petite-caps",
  letterSpacing: "0.0200012em",
  lineHeight: "19px",
  fontWeight: 600,
  opacity: "0.9",
};

const commonBorderButtonStyle = {
  border: `2px solid ${cobalt}`,
  boxSizing: "border-box",
  borderRadius: "4px",
  backgroundColor: "transparent",
  padding: "8px 16px",
};
const commonDialogTextStyle = {
  fontSize: "18px",
  fontVariant: "all-petite-caps",
  letterSpacing: "0.0200012em",
  lineHeight: "19px",
  fontWeight: 600,
  opacity: "0.9",
  justifyContent: "space-evenly !important",
};

const primaryButtonStyle = {
  height: "36px",
  padding: "8px 16px",
  borderRadius: "4px",
  fontSize: "16px",
  fontWeight: 600,
  letterSpacing: "0.32px",
  fontFamily: "Roboto",
  textTransform: "uppercase",
};
const commonFieldTextStyle = { paddingBottom: "8px", color: "rgba(0, 0, 0, 0.8)" };
export const imageStyle = (scale: number, rotate: number) => ({
  editImageStyle: {
    transform: `scale(${scale}) rotate(${rotate}deg)`,
    objectFit: "cover",
    maxWidth: "100%",
    maxHeight: "100%",
  } as CSSProperties,
});

export const pictureFieldStyles = (isDisable?: any, isZoomVisible?: boolean, isRotateVisible?: boolean, isCroppingEnabled?: any): any => ({
  dialogPaper: {
    height: "100vh",
    width: "100vw",
  },
  dialogTitle: {
    padding: "16px 0px 24px 0px",
  },
  imageEditdialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  editDialog: {
    overflowY: "hidden",
    overflowX: "hidden",
  },
  dialogContent: {
    overflow: "hidden",
  },
  customDialogContent: {
    objectFit: "contain",
    scrollbarWidth: "thin",
    alignContent: "center",
    background: gainsBoro,
    marginRight: "24px",
    marginLeft: "24px",
    borderRadius: "4px",
  },
  thumbnailDialogContent: {
    padding: "0 24px 0 24px",
    objectFit: "contain",
    height: "80vh",
  },
  paper: {
    margin: "10px",
    scrollbarWidth: "thin",
    overflowX: "hidden",
  },
  downloadContainer: { width: "100%", display: "flex", alignItems: "center", justifyContent: "center" },
  dialogActions: { flexFlow: "wrap", alignSelf: "center" },
  customDialogActions: { flexFlow: "wrap", alignSelf: "center", paddingTop: "8px", paddingBottom: "8px" },
  customDialogAction: { paddingTop: "2rem", paddingBottom: "16px" },
  dialogAction: { marginBottom: "10px" },
  closeIcon: {
    float: "right",
    paddingRight: "16px",
  },
  galleryCloseIcon: {
    float: "right",
  },
  paperHeight: {
    maxHeight: "calc(100%)",
    height: "590px",
  },
  refreshIcon: { position: "absolute", top: "50%", left: "50%", backgroundColor: "#CCDCF6", opacity: 0.54 },
  cropContainer: {
    position: "relative",
    width: "100%",
    height: "55vh",
    background: gainsBoro,
  },
  imageFit: {
    width: "100%",
    overflow: "hidden",
    maxHeight: "46vh",
    overflowY: "auto",
    overflowX: "auto",
    scrollbarWidth: "thin",
  },
  webCamImageContainer: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: gainsBoro,
    borderRadius: "4px",
    height: "100%",
    overflow: "auto",
    scrollbarWidth: "thin",
  },
  webCamImageFit: {
    overflow: "hidden",
    maxHeight: "40vh",
    overflowY: "auto",
    alignSelf: "center",
  },
  webcam: {
    width: "100%",
    objectFit: "unset",
    overflow: "scroll",
  },
  galleryDialogActions: { flexFlow: "wrap", alignSelf: "flex-start", paddingTop: "2.5rem", paddingBottom: "16px", paddingLeft: "0.7rem" },
  EditorDialogActions: {
    display: "flex",
    justifyContent: "flex-start",
    paddingBottom: "16px",
    paddingLeft: "24px",
    paddingRight: "24px",
  },
  EditorSaveCancelDialog: {
    display: "flex",
    paddingBottom: "24px",
    paddingLeft: "4px",
    paddingRight: "24px",
    gap: "8px",
    justifyContent: "flex-end",
  },
  galleryContent: { padding: "0 34px 0 34px" },
  galleryImage: { width: "100%", objectFit: "cover" },
  iconSettings: {
    fill: "rgb(63, 81, 181)",
    width: "1.5em",
    height: "1.5em",
  },
  deleteIcon: {
    fill: "rgb(244, 67, 54)",
    width: "1.5em",
    height: "1.5em",
  },
  circularIcon: {
    padding: "1em",
  },
  thumnailImage: { width: "4em", height: "4em" },
  cameraIcon: {
    width: "4em",
    height: "4em",
    padding: "0.5em",
    border: "none",
  },
  thumbnailImageOptions: {
    right: 0,
    top: 0,
    position: "absolute",
    display: "flex",
    gap: "16px",
  },
  removeIcon: {
    backgroundColor: "#CCDCF6",
  },
  pictureBox: { flexDirection: "column", alignItems: "flex-start" },
  formLabelColumn: { flex: "none", order: 0, flexGrow: 0 },
  fieldText: commonFieldTextStyle,
  emptyFieldText: { padding: "8px 0px" },
  thumbnailImage: { flex: "none", order: 1, flexGrow: 0, paddingTop: "8px" },
  addPhotoButton: commonBorderButtonStyle,
  addPhotoText: {
    ...commonButtonStyle,
    paddingLeft: "8px",
  },
  downloadIcon: { paddingRight: "10px" },
  requiredText: { order: 2 },
  pictureField: { position: "relative" },
  pictureDialogActions: { alignSelf: "flex-start" },
  dialogActionButtons: { backgroundColor: "transparent", color: cobalt, fontWeight: "600" },
  downloadButtonStyle: { color: cobalt, fontWeight: "600", fontSize: "13px", paddingTop: "8px", paddingBottom: "8px" },
  dialogButtonText: { ...ButtonStyle },
  dialogTitleText: { paddingLeft: "24px", fontSize: "20px", lineHeight: "23px", fontWeight: 600 },
  selectedImageText: { fontSize: "20px", lineHeight: "23px", fontWeight: 600 },
  pictureImage: { width: "100%", objectFit: "unset", overflow: "scroll", cursor: "pointer" },
  dialogRetakeButtonText: { ...ButtonStyle, paddingLeft: "0px" },
  dialogImage: {
    position: "absolute",
    left: "0%",
    right: "0%",
    top: "0%",
    bottom: "0%",
    width: "65px",
    height: "65px",
    paddingTop: "5px",
  },
  gallerydialogActionButtons: { backgroundColor: "transparent", color: cobalt, fontWeight: "600", float: "left", paddingRight: "16px" },
  editIcon: {
    backgroundColor: "#CCDCF6",
  },
  galleryDialogText: {
    color: NONE_MESSAGE_TEXT,
    fontSize: "16px",
    lineHeight: "22.4px",
    fontWeight: "400",
    flexDirection: "column",
    textTransform: "capitalize",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  editorDialogText: {
    color: NONE_MESSAGE_BACKGROUND,
    ...commonDialogTextStyle,
  },
  editorCancleDialogText: {
    color: "#DB3069",
    ...commonDialogTextStyle,
  },
  gallery: {
    marginLeft: "30%",
    top: "-117px",
  },
  dialogStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contentStyle: {
    top: "-20px",
    float: "right",
    paddingRight: "16px",
  },
  saveStyle: {
    ...primaryButtonStyle,
    minWidth: "84px",
    backgroundColor: !isDisable ? disabledButtonColor : cobalt,
  },
  cancelStyle: {
    ...primaryButtonStyle,
    backgroundColor: NONE_MESSAGE_BACKGROUND,
    border: `1px solid ${cancelColor}`,
  },
  cropButton: { color: cobalt, marginRight: "10px", backgroundColor: isCroppingEnabled && "#EAF2FF" },
  rotateButton: { color: cobalt, marginRight: "10px", backgroundColor: isRotateVisible && "#EAF2FF" },
  zoomButton: { color: cobalt, backgroundColor: isZoomVisible && "#EAF2FF" },
  zoomVisible: { display: "flex", justifyContent: "space-between", paddingTop: "16px" },
  zoomSlider: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "5px",
  },
  rotateVisible: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    paddingTop: "16px",
  },
  editButtonContainer: { position: "absolute", left: "6%", marginBottom: "40px" },
  dialogTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  zoomContainer: {
    display: "flex",
    alignItems: "center",
  },
  pictureSelectorIconContainer: {
    padding: "0px 24px",
  },
  imageUploadContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "26px",
    fontSize: "14px !important",
  },
  aspectButtonStyle: {
    color: "#134A9F",
    fontSize: "16px",
    lineHeight: "22.4px",
  },
  aspectButtonLabel: {
    color: "#1E1E1E",
    fontSize: "16px",
    lineHeight: "22.4px",
    fontWeight: "bold",
    marginRight: "8px",
  },
  cropButtonRoot: {
    minHeight: "34px",
    minWidth: "42px",
  },
  activeButtonStyle: "1px solid #134A9F",
});

export const gpsFieldStyles = (location?: any, width?: any): any => ({
  circularIcon: {
    padding: "1em",
  },
  thumnailImage: { width: "4em", height: "4em" },
  locationButton: commonBorderButtonStyle,
  emptyFieldText: { padding: "8px 0px" },
  fieldText: commonFieldTextStyle,
  locationButtonText: {
    ...commonButtonStyle,
    paddingLeft: "8px",
    whiteSpace: "nowrap",
    overFlow: "hidden",
    textOverflow: "ellipsis",
  },
  mapLink: { color: cobalt, textDecoration: "underline", cursor: "pointer" },
  coordinateLinkBox: { flex: "none", order: 0, flexGrow: 0 },
  gpsText: {
    display: "flex",
    alignItems: width < 320 ? "flex-start" : "center",
    flexDirection: width < 320 ? "column" : "row",
    gap: width < 320 && "10px",
  },
  gpsButton: { marginRight: "10px" },
  removeIconContainer: { display: !location && "none" },
});

export const uploadFieldStyles = (status: number): any => ({
  doneIconStyle: {
    verticalAlign: "sub",
    paddingLeft: "10px",
    width: "0.75em",
    height: "0.75em",
    fill: "#3f51b5",
  },
  requiredText: { order: 2, paddingBottom: "1em" },
  helperStyle: { paddingTop: "1em", color: "black" },
  excelTextFieldStyle: { width: "90%" },
  warningMessage: { top: "60px" },
  downloadButtonStyle: {
    backgroundColor: (status === 200 && solitude) || "transparent",
  },
  emptyFieldText: {
    padding: "8px 0px",
  },
  fieldText: {
    paddingBottom: "8px",
    color: "rgba(0, 0, 0, 0.8)",
  },
});

export const searchableDropdownStyle = (createMuiTheme as any)({
  overrides: {
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: cobalt,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        "&$focused $notchedOutline": {
          borderColor: cobalt,
        },
        "& .MuiAutocomplete-endAdornment": {
          top: "calc(50% - 17px) !important",
        },
        "& .MuiAutocomplete-clearIndicator": {
          padding: "4px !important",
        },
        "& .MuiAutocomplete-popupIndicator": {
          padding: "2px !important",
        },
      },
    },
  },
});

export const fieldLabelStyle: any = {
  color: cobalt,
  cursor: "pointer",
  pointerEvents: "visible",
};

export const dropdownOptionStyle = () => ({
  optionBlock: { display: "flex", width: "100% !important" },
  optionValue: { color: grey, paddingLeft: "6px", paddingRight: "6px" },
  popupIcon: { color: cobalt, fontSize: "2rem" },
});

export const drawingFieldStyles = (): any => ({
  addSignatureText: {
    ...commonButtonStyle,
    paddingLeft: "8px",
  },
  canvasPenColor: {
    width: "25px",
    height: "25px",
    float: "right",
    padding: "2px",
  },

  titleHeader: { padding: "10px 24px 5px 24px" },
  canvasThumbnail: { width: "4em", height: "4em", padding: "16px", border: "thin #3f51b5 solid", position: "static" },
  canvasTitleContainer: { display: "flex", justifyContent: "space-between", flexFlow: "wrap", alignItems: "center" },
  canvasTitleIcons: { display: "flex", marginTop: "10px", alignItems: "center", cursor: "pointer" },
  closeIcon: { paddingLeft: "24px" },
  emptyFieldText: { padding: "8px 0px" },
  fieldText: commonFieldTextStyle,
  downloadImgStyle: { height: "100%", width: "100%", border: "1px solid #A9A9A9" },
  downloadImgIcon: { width: "100%", display: "flex", justifyContent: "end" },
  imageBody: { position: "relative" },
  downloadIcon: { cursor: "pointer" },
  buttonColor: { backgroundColor: "#134A9F" },
  buttonTextColor: { color: "#134A9F", fontWeight: "600", fontSize: "13px" },
  dangerButton: { color: "#D00B0B", fontWeight: "500", fontSize: "16px" },
  canvasTitle: { fontWeight: 600 },
  downloadContainer: { width: "100%", display: "flex", alignItems: "center", justifyContent: "center" },
  dialogHeader: { fontSize: "20px", fontWeight: 600, color: "#000000" },
  refreshIconButton: { position: "absolute", top: "30px", left: "30px", backgroundColor: aviaryBlue, opacity: 0.54 },
  signatureButton: { ...commonBorderButtonStyle, backgroundColor: "transparent !important" },
});
