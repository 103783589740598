/* global process */
/* eslint-disable import/prefer-default-export */
export const url = {
  accounts: `${process.env.REACT_APP_IDENTITY_HOST}/accounts`,
  authentication: `${process.env.REACT_APP_IDENTITY_HOST}/login`,
  //logout: `${process.env.REACT_APP_IDM_HOST}/app/logout`,
  logout: `${process.env.REACT_APP_IDENTITY_HOST}/signOut`,
  login: `/login`,
  authenticate: `${process.env.REACT_APP_IDENTITY_HOST}/authorizationStatus`,
  loantest: `${process.env.REACT_APP_IDM_HOST}/app/rest/loantest`,

  account: `${process.env.REACT_APP_IDENTITY_HOST}/account`,

  processDefinition: `${process.env.REACT_APP_TASK_HOST}/app/rest/process-definitions`,
  appDefinitions: `${process.env.REACT_APP_TASK_HOST}/v1/app-definitions`,

  processInstance: `${process.env.REACT_APP_TASK_HOST}/app/rest/processInstance`,
  processInstanceDetail: `${process.env.REACT_APP_TASK_HOST}/app/rest/query/process-instance/`,
  processInstanceExtended: `${process.env.REACT_APP_TASK_HOST}/app/rest/process-instance-extended/`,
  queryProcessInstance: `${process.env.REACT_APP_TASK_HOST}/app/rest/query/process-instances`,
  queryProcessInstanceType: `${process.env.REACT_APP_TASK_HOST}/app/rest/query/processInstances`,
  // queryLoanProcessInstance: `${process.env.REACT_APP_TASK_HOST}/app/rest/query/loanProcessInstances`,
  // queryCustomerProcessInstance: `${process.env.REACT_APP_TASK_HOST}/app/rest/query/customer-process-instances`,

  queryTasks: `${process.env.REACT_APP_TASK_HOST}/app/rest/query/tasks`,
  queryTaskDetail: `${process.env.REACT_APP_TASK_HOST}/app/rest/tasks/`,
  queryForm: `${process.env.REACT_APP_TASK_HOST}/app/rest/taskForms/`,
  queryTaskForm: `${process.env.REACT_APP_TASK_HOST}/app/rest/task-forms/`,
  documentTemplate: `${process.env.REACT_APP_DOCUMENT_HOST}/document-template`,
  userSettings: `${process.env.REACT_APP_TASK_HOST}/app/rest/user-settings`,
  grapesJSForms: `${process.env.REACT_APP_MODELER_HOST}/app/rest/formModels/grapesJSForm/`,
  systemDictionaries: `${process.env.REACT_APP_MODELER_HOST}/app/rest/systemDictionaries`,
  file: `${process.env.REACT_APP_TASK_HOST}/app/rest/file/raw`,
  objectDefinitions: `${process.env.REACT_APP_DATASTORE_HOST}/object-definitions`,
  objectData: `${process.env.REACT_APP_DATASTORE_HOST}/data`,
  objectDataQuery: `${process.env.REACT_APP_QUERY_HOST}/v2/object-data`,
  objectDataDownload: `${process.env.REACT_APP_REPORT_HOST}/v1/object-data`,
  dataStoreProcesses: `${process.env.REACT_APP_TASK_HOST}/datastorelinks/processes`,
  combinedUserTaskModelData: `${process.env.REACT_APP_QUERY_HOST}/v1/combined-user-task`,
  users: `${process.env.REACT_APP_QUERY_HOST}/users`,
  reassignTask: `${process.env.REACT_APP_TASK_HOST}/tasks/action/reassign`,
  variables: `${process.env.REACT_APP_TASK_HOST}/app/rest/query/variables/`,
  webhook: `${process.env.REACT_APP_TASK_HOST}/webhook`,
  passwordSetting: `${process.env.REACT_APP_IDENTITY_HOST}/password-settings`,
  changePassword: "/accounts/password/change",
  passwordReset: "/accounts/password/reset",
  updatePassword: `${process.env.REACT_APP_IDENTITY_HOST}/users/change`,
  unAuthenticatedPasswordSetting: `${process.env.REACT_APP_IDENTITY_HOST}/system/settings/`,
  resetPassword: `${process.env.REACT_APP_IDENTITY_HOST}/accounts/password/reset`,
  queryNotifications: `${process.env.REACT_APP_QUERY_HOST}/v1/notifications`,
  taskNotifications: `${process.env.REACT_APP_TASK_HOST}/notifications/tasks`,
  bulkReassignTask: `${process.env.REACT_APP_TASK_HOST}/tasks/action/bulk-reassign`,
  objectReportDetail: `${process.env.REACT_APP_QUERY_HOST}/v1/object-data`,
  tasks: `${process.env.REACT_APP_QUERY_HOST}/v1/tasks`,
  analysisCheck: `${process.env.REACT_APP_IDENTITY_HOST}/analysis-status`,
  maintenance: "/maintenance",
};
