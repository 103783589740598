import { darkSlateBlue, midNightBlue, snowWhite } from "../theme";

const commonTextStyle = {
  color: midNightBlue,
  fontFamily: "'Inter', sans-serif",
  fontStyle: "normal",
  letterSpacing: "0.08px",
  textAlign: "center" as const,
};

export const maintenanceStyles = (isMobile: boolean) => ({
  appBar: {
    background: snowWhite,
    boxShadow: `0px 6px 16px 0px ${darkSlateBlue}`,
    height: "64px",
    justifyContent: "center",
  },
  gridContainer: {
    flexWrap: "nowrap" as const,
  },
  header: { padding: isMobile ? "20px 20px 0px" : "34px 34px 0px" },
  headerText: {
    ...commonTextStyle,
    fontSize: `${isMobile ? 20 : 32}px`,
    fontWeight: 600,
    lineHeight: "normal",
    maxWidth: "600px",
  },
  icon: { maxWidth: "473px", paddingTop: "64px", width: "100%" },
  subHeader: { padding: "24px 24px 0px" },
  subHeaderText: {
    ...commonTextStyle,
    fontSize: `${isMobile ? 14 : 20}px`,
    fontWeight: 400,
    lineHeight: `${isMobile ? 22 : 38}px`,
    maxWidth: "600px",
  },
});
