import { fetchWrapper } from "../core/fetchWrapper";
import { url } from "./apiConfig";
import { EMPTY, HTTP_METHOD, STATUS_CODES } from "../constants";
import { showNotification } from "../util/notification";
import i18n from "../i18n";

export interface RowData {
  id: string;
  [extraProps: string]: any;
}

export interface RowMapper {
  header: string;
  name: string;
  propName: string;
  type: "string" | "number" | "date";
  options?: any;
}

export interface Cell {
  options?: any;
  name: string;
  label: string;
}

const { GET } = HTTP_METHOD;
const { FORBIDDEN, NOT_AUTHENTICATED, SUCCESS } = STATUS_CODES;

function valueHandler(rowData: RowData, propName: string, type: "string" | "number" | "date"): any {
  const propId = propName.split(".");
  let value = "";
  if (propId.length === 1) {
    value = rowData[propId[0]] || "";
  } else {
    const inner = rowData[propId[0]];
    if (inner) {
      value = inner[propId[1]] || "";
    }
  }

  if (type === "date" && value != "") {
    return Number(new Date(value));
  }
  return value;
}

export function toReportDataTable(origin: RowData[], mapper: RowMapper[]): { header: Cell[]; body: any[] } {
  const data: { header: Cell[]; body: any[] } = { header: [], body: [] };

  mapper.forEach((item, hIndex) => {
    data.header.push({
      name: item.name,
      label: item.header,
      options: item.options,
    });
  });

  if (origin && origin instanceof Object) {
    // loop through all the rows of data
    Object.keys(origin).forEach((rowKey: any) => {
      let cell: any = [];
      // using the mapper find the correct property
      mapper.map(item => {
        cell[item.name] = valueHandler(origin[rowKey], item.propName, item.type);
      });
      if (Object.keys(cell).length !== 0) {
        // flowlint-next-line all:off
        data.body.push({ ...cell });
      }
    });
  }

  return data;
}

export function fetchPreliminaryCheckStatus(callType: string): Promise<Response> {
  return fetch(`${url.analysisCheck}`, {
    method: callType,
  });
}

export const getPreliminaryCheckStatus = async (callType: string) => {
  try {
    const response: Response = await fetchWrapper(fetchPreliminaryCheckStatus, callType);
    const { status } = response;
    if (status === SUCCESS) {
      const data = await response.json();
      return callType == GET ? data.result : data.reportAnalysisCheck;
    }
    if (status !== NOT_AUTHENTICATED && status !== FORBIDDEN) {
      throw new Error(EMPTY);
    }
  } catch (e) {
    showNotification("error", i18n.t("common.serverErrorTryAgain"));
  }
};