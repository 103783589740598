import React, { useContext, useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import { createMuiTheme, Grid, useMediaQuery } from "@material-ui/core";
import _ from "lodash";
import i18n from "../../i18n";
import ObjectDataTable from "./ObjectDataTable";
import ObjectDataForm from "./ObjectDataForm";
import { AccountContext } from "../../App";
import { Document } from "../../redux/types";
import { filterRemoteObjectDataList, getDocuments, getFilterProperties, getGrapesFormModel, loadDictionaries } from "./objectDataReportHelper";
import { ALL, DOCUMENT_TEMPLATE, EMPTY, EQUALS, FEW_PROPERTIES, LAST_UPDATED, LOAN_PRODUCT, REPORT, TableKeys } from "../../constants";
import {
  defaultObjectDataFilterBody,
  defaultObjectDataSortOrder,
  DynamicJson,
  ObjectDataFilterBody,
  ObjectDefinition,
  RemoteObjectData,
} from "../../datastore/dataStoreTypes";
import { getObjectDefinitionHelper } from "../../datastore/datastoreHelper";
import { getRowsPerPageSize } from "../../util/helper";
import { CUT } from "../TeamTask/constants";
import { formType } from "./utils";
import Spinner from "../common/Spinner";
import { ReportListState } from "./reportTypes";
import { defaultReportFilterDialogState, updateReportFilterDialogBlocState } from "../../rxjs/reportFilterDialogBloc";
import * as Sentry from "@sentry/react";
import { objectDataTableBackground } from "./objectDataStyles";
import { defaultReportsState, updateReportsState } from "../../rxjs/reportsBloc";
import { getIAPEmail } from "../../services/GcpIAMService";

interface OwnProps extends RouteComponentProps<{ key: string; version: string; recordId: string }> {}

interface OwnProps extends WithTranslation {
  t: i18n.TFunction;
}

function ObjectDataList(props: OwnProps) {
  const { history, location, match, t } = props;
  const accountContext = useContext(AccountContext);
  var privileges = accountContext && accountContext.privileges ? accountContext.privileges : [];
  const theme = createMuiTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const [remoteObjectData, setRemoteObjectData] = useState<RemoteObjectData>({
    content: [],
    count: 0,
    page: 0,
    pageSize: getRowsPerPageSize(TableKeys.OBJECT_DATA_LISTS),
  });
  const [listState, setListState] = useState<ReportListState>({
    filterList: [],
    isExactSearch: true,
    sortOrder: { name: EMPTY, direction: "desc" },
    isChangeScreen: false,
  });
  const [rerenderTable, setRerenderTable] = useState<boolean>(false);
  const [dictionaries, setDictionaries] = useState(undefined);
  const [documents, setDocuments] = useState<Document[] | undefined>(undefined);
  const [objectDefinition, setObjectDefinition] = useState<ObjectDefinition | undefined>(undefined);
  const [formData, setFormData] = useState<any>(undefined);
  const { key, version, recordId } = match.params;
  let filterBody = defaultObjectDataFilterBody;
  const { content, page, pageSize } = remoteObjectData;
  const classes = objectDataTableBackground();
  const FORMTYPE = formType(objectDefinition);

  let versionNumber: number;
  try {
    versionNumber = version ? Number(version) : 1;
  } catch {
    versionNumber = 1;
  }

  /**
   * This defines what should happen when key and version are changed.
   * When key and version are changed (when user clicks a new report navigation item) fetch corresponding object definition object.
   * This happens when an user clicks a new object definition navigation.
   */
  const onKeyAndVersionChanged = (): void => {
    setObjectDefinition(undefined);
    setFormData(undefined);
    setListState(state => ({
      ...state,
      clonedFormData: undefined,
      filterList: [],
      isExactSearch: defaultReportFilterDialogState.isExactSearch,
      objectDataArray: undefined,
      sortOrder: { name: EMPTY, direction: "desc" },
    }));
    if (location.state) {
      let filterParams = _.cloneDeep(location.state);
      let { filterParams: params } = filterParams;
      const searchTypeFromState = params && params[0] && params[0].comparisonType;
      const isExactSearch = searchTypeFromState ? searchTypeFromState == EQUALS : true;
      updateReportFilterDialogBlocState({
        isExactSearch: isExactSearch,
      });
    } else {
      updateReportFilterDialogBlocState(defaultReportFilterDialogState);
    }
    privileges && privileges.indexOf("access-bo") !== -1 ? getObjectDefinitionHelper(key, versionNumber, setObjectDefinition) : history.push("/");
  };

  useEffect(() => {
    onKeyAndVersionChanged();
    updateReportsState(defaultReportsState);
  }, [key, version]);

  useEffect(() => {
    formData && setListState(state => ({ ...state, clonedFormData: _.cloneDeep(formData) }));
  }, [formData]);

  useEffect(() => {
    loadDictionaries(setDictionaries);
    getDocuments(setDocuments, { scopeCategory: LOAN_PRODUCT, scopeId: DOCUMENT_TEMPLATE });
  }, []);

  useEffect(() => {
    if (objectDefinition) {
      const { cutFormId, formId } = objectDefinition.otherData;
      const id = FORMTYPE === CUT ? cutFormId : formId;
      getGrapesFormModel(FORMTYPE, id, setFormData);
      let { filterList: stateFilters, sortOrder } = listState;
      let order = { ...sortOrder };
      filterBody = {
        ...filterBody,
        page: 0,
        pageSize: pageSize,
      };
      if (location.state) {
        let filterParams = _.cloneDeep(location.state);
        let { filters, pageNumber, pagingSize, sortingOrder, filterParams: params } = filterParams;
        order = { ...sortingOrder };
        filterBody = {
          ...filterBody,
          page: pageNumber,
          pageSize: pagingSize,
          sortParams: { param: order.name, order: order.direction.toUpperCase() },
          filterParams: params,
        };
        stateFilters = filters;
        sortOrder = { ...order };
        history.replace({ state: undefined });
      }
      // initial data is sorted by last updated column
      if (!order.name) {
        sortOrder = defaultObjectDataSortOrder;
        filterBody.sortParams = { param: defaultObjectDataSortOrder.name, order: defaultObjectDataSortOrder.direction.toUpperCase() };
      }
      const isExactSearchFromState =
        filterBody.filterParams && filterBody.filterParams[0] && filterBody.filterParams[0].comparisonType == EQUALS ? true : false;
      setListState(state => ({
        ...state,
        filterList: stateFilters,
        objectDataArray: undefined,
        sortOrder,
        filterParams: filterBody.filterParams,
        isExactSearch: isExactSearchFromState,
      }));
      handleFilterData(filterBody);
    }
  }, [objectDefinition]);

  useEffect(() => {
    const { content: items } = remoteObjectData;
    if (items) {
      var objectDataArrayTemp = items
        ? items.map((item: DynamicJson) => {
            if (item.data) {
              var arrayElement: DynamicJson = {};
              arrayElement["JUAKALI_BO_ID"] = item.id;
              arrayElement[LAST_UPDATED] = item.updated;
              Object.keys(item.data).map(propertyName => {
                var variableValue = item.data[propertyName];
                switch (variableValue) {
                  case undefined:
                  case null:
                    arrayElement[propertyName] = EMPTY;
                    break;
                  default:
                    if (typeof variableValue === "object" || typeof variableValue === "boolean") {
                      var jsonString = JSON.stringify(variableValue);
                      arrayElement[propertyName] = jsonString;
                    } else {
                      arrayElement[propertyName] = variableValue;
                    }
                }
              });
              return arrayElement;
            } else {
              return {};
            }
          })
        : [];
      setListState(state => ({ ...state, objectDataArray: objectDataArrayTemp }));
    }
  }, [remoteObjectData]);

  const handleRedirection = (recordId?: string): void => {
    history.push(`/objectdata/${key}/${version}${recordId ? `/${recordId}` : ""}`);
  };

  const handleBackToList = () => {
    let { clonedFormData } = listState;
    if (formData) {
      clonedFormData = _.cloneDeep(formData);
    }
    setListState(state => ({ ...state, clonedFormData }));
    handleRedirection();
  };

  const handleSelectedRow = (rowData: string[]) => {
    handleRedirection(rowData[0]);
  };

  const addSentryLogs = (boName: string, propertiesLength: number) => {
    Sentry.captureException(
      new Error(
        `BO Report Too many properties Warning - BO: ${boName}, Requested Properties: ${propertiesLength} (> 50), User: ${
          accountContext ? accountContext.email : EMPTY
        }`
      )
    );
  };

  const handleFilterData = (filterBody: ObjectDataFilterBody) => {
    try {
      const properties = getFilterProperties(isMobileView, objectDefinition);
      const propertiesLength = properties.length;
      let requestType = FEW_PROPERTIES;
      if (!propertiesLength || propertiesLength > 50) {
        requestType = ALL;
        addSentryLogs(objectDefinition ? objectDefinition.label : EMPTY, propertiesLength);
      }
      filterRemoteObjectDataList(setRemoteObjectData, setRerenderTable, key, { ...filterBody, properties, requestType });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleScreenChange = () => {
    handleFilterData(filterBody);
  };

  useEffect(() => {
    if (objectDefinition) {
      let { isChangeScreen } = listState;
      !isChangeScreen
        ? handleScreenChange()
        : setListState(state => ({
            ...state,
            isChangeScreen: true,
          }));
    }
  }, [isMobileView]);

  return dictionaries && documents && objectDefinition && listState.objectDataArray ? (
    recordId ? (
      <ObjectDataForm
        formData={listState.clonedFormData}
        content={content}
        dictionaries={dictionaries}
        documents={documents}
        filterParams={{
          filters: listState.filterList,
          pageNumber: page,
          pagingSize: pageSize,
          sortingOrder: listState.sortOrder,
          filterParams: listState.filterParams,
        }}
        handleBackToList={handleBackToList}
        history={history}
        id={recordId}
        t={t}
        formType={FORMTYPE}
      />
    ) : (
      <Grid container className={classes.dataTableBackground}>
        <ObjectDataTable
          filterBody={filterBody}
          formType={FORMTYPE}
          handleFilterData={handleFilterData}
          isMobileView={isMobileView}
          listState={listState}
          objectDefinition={objectDefinition}
          remoteObjectData={remoteObjectData}
          rerenderTable={rerenderTable}
          setListState={setListState}
          t={t}
          handleSelectedRow={handleSelectedRow}
        />
      </Grid>
    )
  ) : (
    <Spinner open={true} />
  );
}

export default withRouter(withTranslation()(ObjectDataList));
