import { createReducer, AnyAction } from "redux-starter-kit";
import { CreateProcessInstance, DynamicJson, defaultCreateProcessInstance } from "../types";
import { startProcessInstance } from "../../services/ProcessInstanceService";
import Log from "../../core/Log";
import { fetchWrapper } from "../../core/fetchWrapper";
import { setCreateProcessInstance } from "../actions";
import { SUCCESS } from "../../constants";

const createProcessInstanceReducer = createReducer<CreateProcessInstance, AnyAction>(defaultCreateProcessInstance, {
  [setCreateProcessInstance.type]: (_: CreateProcessInstance, action: AnyAction) => {
    return action.payload;
  },
});

export default createProcessInstanceReducer;

export function createProcessInstanceOnline(instance: CreateProcessInstance): any {
  return (dispatch: any) => {
    dispatch(setCreateProcessInstance({ ...instance, isCreating: true }));

    if (instance.processDefinitionId && instance.processName) {
      return fetchWrapper(startProcessInstance, { processDefinitionId: instance.processDefinitionId, name: instance.processName })
        .then(
          (response: Response) => {
            return response && response.status === 200 && response.json();
          },
          (error: string) => {
            dispatch(setCreateProcessInstance(defaultCreateProcessInstance));
            Log.error(error);
            return null;
          }
        )
        .then((result: DynamicJson) => {
          if (result && result.data) {
            dispatch(
              setCreateProcessInstance({
                ...instance,
                isCreating: false,
                createStatus: SUCCESS,
                processInstanceId: result.data.id,
              })
            );
          }
          return result;
        });
    }
    return undefined;
  };
}
