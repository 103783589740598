export const Constants = {
  READABLE_CATEGORY: "readonly",
};

export const PROCESSINSTANCE = "Process Instance";
export const EDIT_PROCESS_INSTANCE = "Edit Process Instance";
export const CREATE_PROCESS_INSTANCE = "Create Process Instance";
export const CANCEL_CREATE = "Cancel - Create Process Instance";
export const HYPHEN = "-";
export const EMPTY = "";
export const OBJECT = "object";
export const BLANK = " ";
export const INVALID = "invalid";
export const CUSTOMTYPE = "customType";
export const DEFAULTTYPE = "defaultType";
export const DISABLE_MANDATORY_FIELDS = "DisableMandatoryFields";
export const GRANTED = "granted";
export const OTHER_PROCESSES = "Other processes";

export const LOAN_PRODUCT = "LOAN_PRODUCT";
export const DOCUMENT_TEMPLATE = "DocumentTemplate";
export const BODY = "body";
export const HEADER = "header";
export const MAX_REASSIGNABLE_ROWS = 20;
export const GPSLINK = "http://maps.google.com/?q=";
export const LINKTARGET = "_blank";
export const LAST_UPDATED = "lastUpdated";
export const EQUALS = "EQUALS";
export const CONTAINS = "CONTAINS";
export const EQUAL = "EQUAL";
export const LIKE = "LIKE";

export const FIELD_TYPES = {
  INPUT: "input",
  OBJECT: "object",
  BOOLEAN: "boolean",
  FORMULA: "formula",
  DATE: "date",
  NUMBER: "number",
  DROPDOWN: "dropdown",
  RADIO_GROUP: "radioGroup",
  ERROR_MESSAGE: "error-messages",
  CHECKBOX: "checkbox",
  EXPRESSION: "expression",
  DRAWING: "drawing",
  PICTURE: "picture",
  GPS: "gps",
  UPLOAD: "uploadExcel",
  OUTCOMES: "outcomes",
  MULTILINE_INPUT: "multilineInput",
};

export const SELECT_OPTION = "select-option";
export const CLEAR = "clear";
export const SAVE = "save";
export const CANCEL = "cancel";
export const CHANGEASSIGNEE = "changeAssignee";
export const TEAMVIEW = "teamView";
export const DUEDATE = "dueDate";
export const ASSIGNEE = "assignee";
export const REASSIGN_ERROR = "REASSIGN_ERROR";
export const REASSIGN_SUCCESS = "REASSIGN_SUCCESS";
export const ERROR = "ERROR";
export const SUCCESS = "success";
export const TWO_DIGIT = "2-digit";
export const NUMERIC = "numeric";
export const EVENT = "event";

export const Padding = {
  PADDINGTOP: "paddingTop",
  PADDINGBOTTOM: "paddingBottom",
  PADDINGRIGHT: "paddingRight",
  PADDINGLEFT: "paddingLeft",
};

export const INTEGER = "integer";
export const DECIMAL = "decimal";
export const DATE = "date";
export const ENTER = 13;
export const REGEX_MM_DD_YYYY = /^\d{2}([/-])\d{2}\1\d{4}$/;
export const REGEX_YYYY_MM_DD = /^\d{4}([/-])\d{2}\1\d{2}$/;
export const REGEX_YYYY_MM = /^\d{4}([/-])\d{2}$/;
export const REGEX_MM_YYYY = /^\d{2}([/-])\d{4}$/;
export const REGEX_YYYY = /^\d{4}$/;
export const REGEX_DD_MM = /^\d{2}([/-])\d{2}$/;

export const TableKeys = {
  OBJECT_DATA_LISTS: "object-data-lists",
  ASSIGNED_TASK_LISTS: "assigned-task-lists",
  CANDIDATE_TASK_LISTS: "candidate-task-lists",
  TEAM_TASK_LISTS: "team-task-lists",
};
export const TABLEKEYS = "juakali-table-keys";
export const DEFAULT_ROWS_PER_PAGE = 10;
export const MANUAL_INPUT = "manualInput";
export const USE_DICTIONARY = "useDictionary";
export const HTTP_METHOD = {
  DELETE: "DELETE",
  GET: "GET",
  PATCH: "PATCH",
  POST: "POST",
  PUT: "PUT",
};

export const STATUS_CODES = {
  DEFAULT: 1,
  LOADING: 0,
  SERVER_TOO_BUSY: 429,
  SUCCESS: 200,
  TEMPORARY_REDIRECT: 307,
  BAD_REQUEST: 400,
  NOT_FOUND: 404,
  NOT_AUTHENTICATED: 401,
  FORBIDDEN: 403,
  SERVER_ERROR: 500,
  UN_PROCESSABLE_ENTITY: 422,
  SERVICE_UNAVAILABLE: 503,
};

export const REPORTS = "reports";
export const TASKS = "tasks";

export const SHEET = "sheet";
export const XLSX = "xlsx";
export const DOCUMENT = "document";
export const DOCX = "docx";
export const INCLUDE = "include";

export const PROCESS = "process";
export const TASK = "task";
export const DOT = ".";
export const OOB_CODE = "OOB_CODE";
export const ACCESS_TEST = "access-test";
export const SHOW_NOTIFICATION_POPUP = "showNotificationPopup";
export const TRUE = "true";
export const EXCEL = "excel";
export const PDF_FORMAT = "pdf";
export const EXCEL_FORMAT = "xlsx";
export const ACCEPTED_FORMATS = ".xlsx, .pdf";
export const ACCEPTED_PICTUREFORMATS = ".png, .jpg, .jpeg";
export const FILE_SIZE_ERROR = "fileSizeError";
export const FILE_FORMAT_ERROR = "fileFormatError";
export const CALCULATION = "Calculation";
export const FORMULA = "Formula";
export const DEFAULT_VALUE = "defaultValue";
export const DEFAULT_FIRST_VALUE = "defaultFirstValue";
export const DISPLAY_ONLY = "displayOnly";
export const STRING = "string";
export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const FEW_PROPERTIES = "FEW_PROPERTIES";
export const ALL = "ALL";
export const DATE_FORMAT = "YYYY-MM-DD";
export const REPORT = "report";
export const RECORDS = "records";
